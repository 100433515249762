/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Box, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import VideoPlayer from '../components/VideoPlayer';
import FeatureHighlights from '../components/FeatureHighlights';
import CallToAction from '../components/CallToAction';
import videoPosterImage from '../assets/images/mirage-world-nearby.png';
import pressImageApple from '../assets/images/apple-logo@2x.png';
import pressImageWired from '../assets/images/wired-logo@2x.png';
import pressImageItsNiceThat from '../assets/images/its-nice-that-logo@2x.png';
import pressImageDezeen from '../assets/images/de-zeen-logo@2x.png';

const pressListStyle = css`
  & a:hover {
    border-bottom: 0px;
  }

  & img {
    max-width: 100%;
    object-fit: scale-down;
  }
`;

const MirageIndex = props => (
  <PageWrapper>
    <Helmet
      /* eslint-disable */
      title="mirage world"
      meta={[
        { name: 'author', content: 'mirage worlds, inc.' },
        { name: 'description', content: 'a shared world on top of reality – download for iOS' },
        { name: 'keywords', content: 'mirage, mirage world, mirage app, augmented reality, mixed reality, AR, immersive, immersive world, spatial, iOS, iPhone, Apple' },
        { name: 'robots', content: 'INDEX,FOLLOW' },
        { name: 'distribution', content: 'Global' },
        { name: 'revisit-after', content: '1 days' },
        { name: 'google-site-verification', content: 'Th19yWrbZzp9siWM2ZKRCPb165IJJBkDshxXINqjY30' },
        { name: 'viewport', content: 'width=device-width, maximum-scale=1.0' },
        { name: 'twitter:card', content: 'photo' },
        { name: 'twitter:title', content: 'mirage' },
        { name: 'twitter:description', content: 'a shared world on top of reality – download for iOS' },
        { name: 'twitter:image', content: 'https://mirage.world/MirageWorldShare.png' },
        { name: 'twitter:image:width', content: '800' },
        { name: 'twitter:image:height', content: '450' },
        { property: 'og:title', content: 'mirage' },
        { property: 'og:description', content: 'a shared world on top of reality – download for iOS' },
        { property: 'og:image', content: 'https://mirage.world/MirageWorldShare.png' },
        { property: 'og:image:width', content: '800' },
        { property: 'og:image:height', content: '450' },
        { property: 'og:url', content: 'https://mirage.world/' },
        { itemprop: 'name', content: 'mirage' },
        { itemprop: 'description', content: 'a shared world on top of reality – download for iOS' },
        { itemprop: 'image', content: 'https://mirage.world/MirageWorldShare.png' },
        { name: 'apple-itunes-app', content: 'app-id=1373833186, app-argument=mirage://' }
        /* eslint-enable */
      ]}
    />
    <VideoPlayer
      src="/assets/mirage-world-web-nearby.mp4"
      type="video/mp4"
      playsInline
      autoPlay
      loop
      muted
      poster={videoPosterImage}
    />
    <Box width={9 / 10} m="auto" py={[null, null, 100, 100]} justify="center">
      <FeatureHighlights />
    </Box>
    <Box textAlign="center">
      <Box width={1} pt={100}>
        <CallToAction />
      </Box>
      <Box width={1} py={100} m="auto">
        <div className={pressListStyle}>
          <Flex wrap="wrap">
            <Box width={[1, 1, 1 / 4]} py={3} px="25">
              <a
                title="apple"
                href={props.apple}
                target="_blank"
                rel="noopener"
              >
                <img src={pressImageApple} alt="apple" width="55" height="65" />
              </a>
            </Box>
            <Box width={[1, 1, 1 / 4]} py={3} px="25">
              <a
                title="wired"
                href={props.wired}
                target="_blank"
                rel="noopener"
              >
                <img
                  src={pressImageWired}
                  alt="wired"
                  width="227"
                  height="46"
                />
              </a>
            </Box>
            <Box width={[1, 1, 1 / 4]} py={3} px="25">
              <a
                title="its nice that"
                href={props.itsnicethat}
                target="_blank"
                rel="noopener"
              >
                <img
                  src={pressImageItsNiceThat}
                  alt="its nice that"
                  width="234"
                  height="45"
                />
              </a>
            </Box>
            <Box width={[1, 1, 1 / 4]} py={3} px="25">
              <a
                title="dezeen"
                href={props.dezeen}
                target="_blank"
                rel="noopener"
              >
                <img
                  src={pressImageDezeen}
                  alt="de zeen"
                  width="120"
                  height="63"
                />
              </a>
            </Box>
          </Flex>
        </div>
      </Box>
    </Box>
  </PageWrapper>
);

MirageIndex.propTypes = {
  apple: PropTypes.string,
  wired: PropTypes.string,
  itsnicethat: PropTypes.string,
  dezeen: PropTypes.string
};

/* eslint-disable */
MirageIndex.defaultProps = {
  apple: 'https://twitter.com/AppStore/status/903386277705388032',
  wired: 'https://www.wired.com/story/mirage-augmented-reality-app/',
  itsnicethat: 'https://mobile-ar.reality.news/news/mirage-world-sticks-ephemeral-memes-real-world-locales-via-iphone-without-arkit-0179411/',
  dezeen: 'https://www.dezeen.com/2017/08/22/augmented-reality-app-mirage-lets-you-add-emojis-to-real-life-one-minute-movie/'
};
/* eslint-enable */

export default MirageIndex;
