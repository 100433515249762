import React from 'react';
import styled from 'react-emotion';
import colors from '../../utils/colors';
import { Box } from '../../components/Layout';
import FeatureImageFX from '../../components/FeatureImageFX';
import ctaAppImage from '../../assets/images/app-icon-cta@2x.png';

const HeaderLink = styled.h2`
  color: ${colors.yellow};

  & a {
    text-decoration: none;
    color: ${colors.yellow};
  }

  & a:hover {
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid ${colors.yellow};
  }
`;

const CallToAction = () => (
  <Box align="center" mb={100}>
    <Box>
      <a
        title="download for iOS"
        href="https://mirage.world/app"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FeatureImageFX>
          <img src={ctaAppImage} alt="app icon" width="299" height="296" />
        </FeatureImageFX>
      </a>
    </Box>
    <Box mb={40} mt={-80}>
      <HeaderLink>
        <a
          title="download for iOS"
          href="https://mirage.world/app"
          target="_blank"
          rel="noopener noreferrer"
        >
          download for iOS
        </a>
      </HeaderLink>
    </Box>
  </Box>
);

export default CallToAction;
