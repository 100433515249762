import React from 'react';
import { Link } from 'gatsby';
import { css } from 'react-emotion';
import colors from '../../utils/colors';
import media from '../../utils/media';
import { Box } from '../../components/Layout';
import FeatureImageFX from '../../components/FeatureImageFX';
import mirageLogoImage from '../../assets/images/mirage@2x.gif';
import appIconImage from '../../assets/images/app-icon@2x.png';

const basicNav = css`
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  background: linear-gradient(to right, ${colors.purple} 35%, ${colors.red});
  margin: 0;
  width: 100%;
  list-style-type: none;
  z-index: 9999;
  height: 5rem;

  & a {
    text-decoration: none;
    color: ${colors.purple};
  }

  & a:hover {
    text-decoration: none;
    border-bottom: 1px solid ${colors.purple};
    cursor: pointer;
  }

  & ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const fullNav = css`
  ${basicNav};
  display: none;

  & li {
    margin: auto 0;
  }

  & li img {
    display: inline-block;
    vertical-align: middle;
  }

  & ul div {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    & li {
      margin-left: 1.25rem;
      font-weight: 500;
    }
  }

  ${media.mid`
    display: flex;
  `};
`;

// Styles for the mobile View of the navigation
const mobileNav = css`
  ${basicNav};

  height: 4rem;

  & li {
    margin: 0;
  }

  & li:last-child {
    padding-top: 5px;
    margin-left: auto;
    font-weight: 600;
    cursor: pointer;
  }

  & li img {
    display: block;
    margin: auto;
  }

  ${media.mid`
    display: none;
  `};
`;

function Navigation() {
  return (
    <nav>
      <Box width="100%" px={[3, 3, 4]} className={fullNav}>
        <ul>
          <li>
            <Link to="/">
              <img src={mirageLogoImage} alt="mirage" width="122" height="41" />
            </Link>
          </li>
          <div>
            <li>
              <a
                title="download for iOS"
                href="https://mirage.world/app"
                target="_blank"
                rel="noopener noreferrer"
              >
                download for iOS
              </a>
            </li>
            <li>
              <a
                title="download for iOS"
                href="https://mirage.world/app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FeatureImageFX>
                  <img
                    src={appIconImage}
                    alt="app icon"
                    width="60"
                    height="60"
                  />
                </FeatureImageFX>
              </a>
            </li>
          </div>
        </ul>
      </Box>
      <Box width="100%" px={[3, 3, 4]} className={mobileNav}>
        <ul>
          <li>
            <Link to="/">
              <img src={mirageLogoImage} alt="mirage" width="84" height="28" />
            </Link>
          </li>
          <li>
            <div>
              <a
                title="download for iOS"
                href="https://mirage.world/app"
                target="_blank"
                rel="noopener noreferrer"
              >
                download
              </a>
            </div>
          </li>
        </ul>
      </Box>
    </nav>
  );
}

export default Navigation;
