import styled, { css } from 'react-emotion';

const featureImageFX = css`
  & img {
    max-width: none;

    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transform: scale3d(0.85, 0.85, 1);
    transform: scale3d(0.85, 0.85, 1);
  }

  & img:hover {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

const FeatureImageFX = styled.div`
  ${featureImageFX};
`;

export default FeatureImageFX;
