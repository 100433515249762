import React from 'react';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../../utils/colors';
import { Box, Flex } from '../Layout';

const FooterStyle = styled.div`
  color: ${colors.purple};
  height: 35rem;
  background: linear-gradient(${colors.purple}, ${colors.red});
  text-align: center;
  font-size: 14pt;

  & a {
    text-decoration: none;
    color: ${colors.yellow};
  }

  & a:hover {
    text-decoration: none;
    border-bottom: 1px solid ${colors.yellow};
    cursor: pointer;
  }
`;

const SocialList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  & li {
    display: inline;
    padding: 20px;
  }

  & a:hover {
    border-bottom: 0px;
  }

  & img {
    width: 20px;
    height: 20px;
  }
`;

const CopyrightStyle = styled.div`
  color: ${colors.purple};
  font-size: 10pt;
  margin-bottom: 1.45rem;

  & a {
    text-decoration: none;
    color: ${colors.purple};
  }

  & a:hover {
    text-decoration: none;
    border-bottom: 1px solid ${colors.purple};
    cursor: pointer;
  }

  & p {
    color: ${colors.purple};
  }
`;

const Footer = props => (
  <FooterStyle>
    <Box width={1} pt={[100, 100, 100, 325]} px={[null, null, 100]}>
      <Box pt={35} pb={20} align="center">
        <SocialList>
          <li>
            <a
              title="follow us on instagram"
              href={props.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/images/instagram.svg" alt="Instagram" />
            </a>
          </li>
          <li>
            <a
              title="follow us on twitter"
              href={props.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/images/twitter.svg" alt="Twitter" />
            </a>
          </li>
          <li>
            <a
              title="follow us on snapchat"
              href={props.snapchat}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/images/snapchat.svg" alt="Snapchat" />
            </a>
          </li>
          <li>
            <a
              title="friend us on mirage"
              href={props.mirage}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/images/mirage.svg" alt="mirage" />
            </a>
          </li>
        </SocialList>
      </Box>
      <Box px={1} pt={20} align="center">
        <CopyrightStyle>
          <span role="img" aria-label="copyright" /* eslint-disable */>
            <p>&copy; {(new Date().getFullYear())} <a href="https://www.crunchbase.com/organization/mirage-worlds-inc" target="_blank" rel="noopener noreferrer">mirage world</a></p>
          </span /* eslint-enable */>
        </CopyrightStyle>
      </Box>
    </Box>
  </FooterStyle>
);

Footer.propTypes = {
  mailinglist: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  snapchat: PropTypes.string,
  mirage: PropTypes.string
};

Footer.defaultProps = {
  mailinglist: 'http://eepurl.com/cLey5j',
  instagram: 'https://instagram.com/mirageworldapp',
  twitter: 'https://twitter.com/mirageworldapp',
  snapchat: 'https://snapchat.com/add/mirageworlds',
  mirage: 'https://mirage.world/user/mirage'
};

export default Footer;
