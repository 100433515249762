import React from 'react';
import styled, { injectGlobal } from 'react-emotion';
import { fontSize } from 'styled-system';
import PropTypes from 'prop-types';
import { Box } from '../Layout';
import Navigation from '../Navigation';
import Footer from '../Footer';
import colors from '../../utils/colors';

// https://github.com/jxnblk/reflexbox
// https://github.com/saschajullmann/gatsby-starter-gatsbythemes

/* eslint-disable */
import normalize from 'normalize.css';
injectGlobal`

  @font-face {
    font-family: 'Graphik LC Web';
    src: url('/assets/fonts/Graphik/Graphik-SuperItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-SuperItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-SuperItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-SuperItalic-Web.woff') format('woff');
    font-weight:  900;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-SuperItalic-Web {
    font-family: 'Graphik LC Web';
    font-weight:  900;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik LC Web';
    src: url('/assets/fonts/Graphik/Graphik-Super-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Super-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Super-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Super-Web.woff') format('woff');
    font-weight:  900;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Super-Web {
    font-family: 'Graphik LC Web';
    font-weight:  900;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-BlackItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-BlackItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-BlackItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-BlackItalic-Web.woff') format('woff');
    font-weight:  800;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-BlackItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  800;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-Black-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Black-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Black-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Black-Web.woff') format('woff');
    font-weight:  800;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Black-Web {
    font-family: 'Graphik Web';
    font-weight:  800;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-BoldItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-BoldItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-BoldItalic-Web.woff') format('woff');
    font-weight:  700;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-BoldItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  700;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-Bold-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Bold-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Bold-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Bold-Web.woff') format('woff');
    font-weight:  700;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Bold-Web {
    font-family: 'Graphik Web';
    font-weight:  700;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-SemiboldItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-SemiboldItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-SemiboldItalic-Web.woff') format('woff');
    font-weight:  600;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-SemiboldItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  600;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik LC Web';
    src: url('/assets/fonts/Graphik/Graphik-Semibold-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Semibold-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Semibold-Web.woff') format('woff');
    font-weight:  600;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Semibold-Web {
    font-family: 'Graphik LC Web';
    font-weight:  600;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-MediumItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-MediumItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-MediumItalic-Web.woff') format('woff');
    font-weight:  500;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-MediumItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  500;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-Medium-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Medium-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Medium-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Medium-Web {
    font-family: 'Graphik Web';
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-RegularItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-RegularItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-RegularItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-RegularItalic-Web.woff') format('woff');
    font-weight:  400;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-RegularItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  400;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/fonts/Graphik-Regular-Web.eot');
    src: url('/fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Graphik-Regular-Web.woff2') format('woff2'),
         url('/fonts/Graphik-Regular-Web.woff') format('woff');
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Regular-Web {
    font-family: 'Graphik Web';
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-LightItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-LightItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-LightItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-LightItalic-Web.woff') format('woff');
    font-weight:  300;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-LightItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  300;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-Light-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Light-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Light-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Light-Web.woff') format('woff');
    font-weight:  300;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Light-Web {
    font-family: 'Graphik Web';
    font-weight:  300;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-ExtralightItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-ExtralightItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-ExtralightItalic-Web.woff') format('woff');
    font-weight:  200;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-ExtralightItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  200;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-Extralight-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Extralight-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Extralight-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Extralight-Web.woff') format('woff');
    font-weight:  200;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Extralight-Web {
    font-family: 'Graphik Web';
    font-weight:  200;
    font-style:   normal;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-ThinItalic-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-ThinItalic-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-ThinItalic-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-ThinItalic-Web.woff') format('woff');
    font-weight:  100;
    font-style:   italic;
    font-stretch: normal;
  }

  .Graphik-ThinItalic-Web {
    font-family: 'Graphik Web';
    font-weight:  100;
    font-style:   italic;
    font-stretch: normal;
  }


  @font-face {
    font-family: 'Graphik Web';
    src: url('/assets/fonts/Graphik/Graphik-Thin-Web.eot');
    src: url('/assets/fonts/Graphik/Graphik-Thin-Web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Graphik/Graphik-Thin-Web.woff2') format('woff2'),
         url('/assets/fonts/Graphik/Graphik-Thin-Web.woff') format('woff');
    font-weight:  100;
    font-style:   normal;
    font-stretch: normal;
  }

  .Graphik-Thin-Web {
    font-family: 'Graphik Web';
    font-weight:  100;
    font-style:   normal;
    font-stretch: normal;
  }

  * {
     -moz-font-feature-settings: "liga", "kern";
     -moz-font-feature-settings:"liga=1, kern=1";
     -ms-font-feature-settings:"liga", "kern";
     -o-font-feature-settings:"liga", "kern";
     -webkit-font-feature-settings:"liga", "kern";
     font-feature-settings: "liga", "kern";
     box-sizing: border-box;
     color: ${colors.yellow};
  }

  body {
    background-color: ${colors.purple};
    margin: 0;
    padding: 0;

    font-family: 'Graphik Web',
                  Helvetica,
                  'Helvetica Neue',
                  Arial,
                  sans-serif;
  }
`;
/* eslint-enable */

const FontConfig = styled.div`
  ${fontSize};
`;

const Layout = ({ children }) => (
  <FontConfig fontSize={[2, 3, 4]}>
    <Navigation />
    <Box py={['4rem', '4rem', '5rem', '5rem']}>{children}</Box>
    <Footer />
  </FontConfig>
);

Layout.propTypes = {
  children: PropTypes.func.isRequired
};

export default Layout;
