/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import colors from '../../utils/colors';
import { Box, Flex } from '../../components/Layout';
import featureCreativeToolsImage from '../../assets/images/feature-creative-tools@2x.gif';
import featurePlacesImage from '../../assets/images/feature-places@2x.gif';
import featureSpatialImage from '../../assets/images/feature-spatial@2x.gif';
import featureRecordImage from '../../assets/images/feature-record@2x.gif';

// https://github.com/jxnblk/reflexbox

const Heading = styled.h1`
  color: ${colors.red};
`;

const Paragraph = styled.p`
  color: ${colors.yellow};
`;

const FeatureHighlightsStyle = styled.div`
  & img {
    object-fit: scale-down;
  }
`;

const FeatureHighlights = props => (
  <FeatureHighlightsStyle>
    {
      // reality is your canvas
    }
    <Box width={1} pt={[150, 150, 150, 200]} m="auto">
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        wrap={['wrap', 'wrap', 'wrap', 'nowrap']}
        justifyContent="center"
      >
        <Box
          width={[1, 1, 1, 1 / 3]}
          mt={[null, null, null, 45]}
          px={[15, 15, null, null]}
          alignSelf="center"
          textAlign={['center', 'center', 'center', 'left']}
        >
          <img
            src={featureCreativeToolsImage}
            alt="freeform creative tools"
            width="220"
            height="220"
          />
        </Box>
        <Box
          width={[1, 1, 1, 1 / 2]}
          mt={[10, null, null, null]}
          alignSelf="center"
          textAlign={['center', 'center', 'center', 'left']}
        >
          <Heading>{props.createHeading}</Heading>
          <Paragraph>{props.createDescription}</Paragraph>
        </Box>
      </Flex>
    </Box>
    {
      // make your mark on the world
    }
    <Box width={1} pt={[150, 150, 150, 250]} m="auto">
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        justify="center"
        wrap={['wrap', 'wrap', 'wrap', 'nowrap']}
        justifyContent="center"
      >
        <Box
          width={[1, 1, 1, 1 / 2]}
          mt={[-50, null, null, null]}
          alignSelf="center"
          textAlign={['center', 'center', 'center', 'left']}
          order={[2, 2, 2, 1]}
        >
          <Heading>{props.placesHeading}</Heading>
          <Paragraph>{props.placesDescription}</Paragraph>
        </Box>
        <Box
          width={[1 / 2, 1, 1, 1 / 3]}
          mt={[null, null, null, 20]}
          px={[null, 15, null, null]}
          alignSelf="center"
          textAlign={['center', 'center', 'center', 'right']}
          order={[1, 1, 1, 2]}
        >
          <img
            src={featurePlacesImage}
            alt="place-based"
            width="240"
            height="240"
          />
        </Box>
      </Flex>
    </Box>
    {
      // think outside the feed
    }
    <Box width={1} pt={[150, 150, 150, 250]} m="auto">
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="center"
        wrap={['nowrap', 'nowrap', 'nowrap', 'nowrap']}
      >
        <Box
          width={[1, 1, 1, 1 / 3]}
          mt={[null, null, null, 45]}
          px={[15, 15, null, null]}
          alignSelf="center"
          textAlign={['center', 'center', 'center', 'left']}
        >
          <img
            src={featureSpatialImage}
            alt="think outside the feed"
            width="280"
            height="280"
          />
        </Box>
        <Box
          width={[1, 1, 1, 1 / 2]}
          mt={[-40, null, null, null]}
          alignSelf="center"
          textAlign={['center', 'center', 'center', 'left']}
        >
          <Heading>{props.spatialHeading}</Heading>
          <Paragraph>{props.spatialDescription}</Paragraph>
        </Box>
      </Flex>
    </Box>
    {
      // sharing
    }
    <Box width={1} pt={[150, 150, 150, 250]} m="auto">
      <Flex
        flexDirection="column"
        justify="center"
        wrap={['nowrap', 'nowrap', 'nowrap', 'nowrap']}
      >
        <Box textAlign="center">
          <Box
            width={[1 / 2, 1 / 2, 1, 1]}
            px={[15, 35, null, null]}
            mx="auto"
            align="center"
          >
            <img
              src={featureRecordImage}
              alt="share your experiences"
              width="200"
              height="200"
            />
          </Box>
          <Box mt={[-15, null, null, null]}>
            <Heading>{props.shareHeading}</Heading>
            <Paragraph>{props.shareDescription}</Paragraph>
          </Box>
        </Box>
      </Flex>
    </Box>
  </FeatureHighlightsStyle>
);

FeatureHighlights.propTypes = {
  createHeading: PropTypes.string,
  createDescription: PropTypes.string,
  placesHeading: PropTypes.string,
  placesDescription: PropTypes.string,
  spatialHeading: PropTypes.string,
  spatialDescription: PropTypes.string,
  shareHeading: PropTypes.string,
  shareDescription: PropTypes.string
};

/* eslint-disable */
FeatureHighlights.defaultProps = {
  createHeading: 'reality is your canvas.',
  createDescription: 'create mirages out of thin air with spatial photos, objects, text, hashtags, gifs, and drawings.',
  placesHeading: 'make your mark on the world.',
  placesDescription: 'add mirages to real-world places where others can find, view, and like them.',
  spatialHeading: 'think outside the feed.',
  spatialDescription: 'communicate with your friends in 3D.',
  shareHeading: 'share your experiences.',
  shareDescription: 'take video and photos of mirages to share on antiquated social networks.'
};
 /* eslint-enable */

export default FeatureHighlights;
