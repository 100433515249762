import styled from 'react-emotion';
import {
  space,
  flex,
  width,
  height,
  fontSize,
  color,
  size,
  order,
  textAlign,
  justifySelf,
  alignContent,
  alignSelf,
  alignItems,
  flexDirection,
  justifyContent,
  style
} from 'styled-system';

// https://github.com/jxnblk/styled-system

const wrap = style({
  prop: 'wrap',
  cssProperty: 'flexWrap'
});

export const Box = styled.div`
  overflow: hidden;
  ${fontSize} ${color} ${size} ${space} ${width} ${height} ${order} ${textAlign} ${justifySelf} ${alignSelf} ${alignContent} ${alignItems};
`;

export const Flex = styled.div`
  display: flex;
  ${flex} ${wrap} ${flexDirection} ${space} ${width} ${height} ${justifyContent} ${alignSelf} ${alignContent} ${alignItems};
`;
